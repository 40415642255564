@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@400;700&display=swap');

@tailwind base;


@tailwind components;


@tailwind utilities;

/* @Layer components { */
    .application-button {
        @apply bg-white justify-center border-red-400 rounded py-2 px-3 font-bold text-red-400 hover:bg-red-400 hover:text-white hover:border-red-400 border max-w-full w-80
    }
    .application-button-full {
        @apply bg-white justify-center border-red-400 rounded py-2 px-3 font-bold text-red-400 hover:bg-red-400 hover:text-white hover:border-red-400 border max-w-full
    }
    .mausch-table-header {
        @apply px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider
    }
/* } */